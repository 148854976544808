/* ------------------------------------------------------------------------------
@name: GalleryDetail
@description: GalleryDetail
--------------------------------------------------------------------------------- */

// - WebSocket
// import { WebSocketIO } from "./WebSocket";

// --- Gallery
import Gallery from "./Gallery";

const GalleryDetail = (() => {
  // --- selector
  const $elementDetail = $(".js-gallery-detail");
  const $elementAudio = $(".js-gallery-audios .a17-gallery__audio__detail audio");
  const $elementVideo = $(".js-gallery-videos video");

  let speed = 50;
  let typeIndex = 0;
  let endDetail = true;

  // description
  let description = [];
  let descriptionCount = 0;
  let descriptionPosision = 0;
  const description1Duration = 12200;
  const description2Duration = 5000;

  // --- handlePlay
  const handlePlay = (data) => {
    if (endDetail) {
      AudioDetail.play();
      VideoDetail.play();
      VideoDetail.watch();
      $("body").addClass("a17--show-detail");

      if (descriptionPosision !== 0) {
        $("body").addClass("a17--reverse-content");
        descriptionPosision = 0;
      } else {
        descriptionPosision = 1;
      }

      endDetail = false;
      typeIndex = 0;

      // -- img
      const _image = `<img class="a17-gallery__detail__img__el" src="${data.image}" alt="${data.nickname}"/>`;
      $elementDetail.find(".a17-gallery__detail__img").html(_image);

      // -- name
      const _names = data.nickname.split("");
      let _nameString = "";
      $.each(_names, (idx, val) => {
        _nameString += `<span>${val}</span>`;
      });

      // -- description
      description = data.description;
      descriptionCount = data.description.length;
      let descriptionString = "";
      $.each(description, (idx, val) => {
        let _name = "";
        if (idx == 0) {
          _name = `<h1 class="a17-gallery__detail__name">${_nameString}</h1>`;
        }

        descriptionString += `<div class="a17-gallery__detail__item">
                              ${_name}
                              <p class="a17-gallery__detail__desc"><span>${val}</span></p>
                            </div>`;
      });
      $elementDetail.find(".a17-gallery__detail__content").html(descriptionString);

      speed = description1Duration / description[0].length;
      // -- set height and show description 0
      handleSetHeightDescription();

      const playTO = setTimeout(() => {
        $("body").removeClass("a17--show");
        clearTimeout(playTO);
      }, 3000);
    }
  };

  // --- handleSetHeightDescription
  const handleSetHeightDescription = (type = "play") => {
    $.each($elementDetail.find(".a17-gallery__detail__item"), (idx, el) => {
      const _descEl = $(el).find(".a17-gallery__detail__desc");
      if (type !== "play") {
        $(el).find(".a17-gallery__detail__desc").removeAttr("style");
        const setHeightTO = setTimeout(() => {
          _descEl.height(_descEl.height());
          clearTimeout(setHeightTO);
        }, 20);
      } else {
        _descEl.height(_descEl.height());
      }
    });

    if (type === "play") {
      $elementDetail.find(".a17-gallery__detail__item").find(".a17-gallery__detail__desc span").text("");
      // -- show description 0
      handleShowDescription(0);
    }
  };

  // --- handleShowDescription
  const handleShowDescription = (index) => {
    handleTypingEffect($($elementDetail.find(".a17-gallery__detail__item")[index]).find(".a17-gallery__detail__desc span"), description[index]);
  };

  // --- handleTypingEffect
  const handleTypingEffect = ($element, description) => {
    if (typeIndex < description.length) {
      $element.text($element.text() + description.charAt(typeIndex));
      typeIndex++;
      const typeEffectTO = setTimeout(() => {
        handleTypingEffect($element, description);
        clearTimeout(typeEffectTO);
      }, speed);
    }
  };

  // --- handleRandom
  const handleRandom = () => {
    const _min = 1;
    const _max = 4;
    return Math.floor(Math.random() * (_max - _min + 1) + _min) - 1;
  };

  // --- AudioDetail
  const AudioDetail = {
    item: 0,
    play: () => {
      const _random = handleRandom();
      if (_random !== AudioDetail.item) {
        AudioDetail.item = _random;
      } else {
        AudioDetail.item = _random === 3 ? _random - 1 : _random + 1;
      }
      $elementAudio[AudioDetail.item].play();
      $elementAudio[AudioDetail.item].currentTime = 0;
      $elementAudio[AudioDetail.item].muted = false;
    },
    off: () => {
      $elementAudio[AudioDetail.item].animate({ volume: 0 }, 1000);
    },
    pause: () => {
      $elementAudio[AudioDetail.item].pause();
    },
    stop: () => {
      $elementAudio[AudioDetail.item].muted = true;
      AudioDetail.pause();
      $elementAudio[AudioDetail.item].currentTime = 0;
    },
  };

  // --- VideoDetail
  const VideoDetail = {
    item: 0,
    play: () => {
      const _random = handleRandom();
      if (_random !== VideoDetail.item) {
        VideoDetail.item = _random;
      } else {
        VideoDetail.item = _random === 3 ? _random - 1 : _random + 1;
      }
      $elementVideo[VideoDetail.item].play();
      $($elementVideo[VideoDetail.item]).addClass("showed");
      $elementVideo[VideoDetail.item].currentTime = 0;
    },
    pause: () => {
      $elementVideo[VideoDetail.item].pause();
    },
    stop: () => {
      VideoDetail.pause();
      $elementVideo[VideoDetail.item].currentTime = 0;
      $($elementVideo[VideoDetail.item]).removeClass("showed");
    },
    loopDetail: () => {
      $elementVideo[VideoDetail.item].currentTime = 22;
    },
    watch: () => {
      let _currentTime = 0;
      let _videoDetail = setInterval(() => {
        _currentTime = Math.round($elementVideo[VideoDetail.item].currentTime);

        // -- show img on 3s but still hide
        if (_currentTime == 3) {
          $("body").addClass("a17--show-img");
        }

        // -- zoom img on 12s
        if (_currentTime == 12) {
          $("body").addClass("a17--zoom-in-img-detail");
        }

        if (_currentTime == 20) {
          $("body").addClass("a17--show-name a17--restart");
        }

        // -- show description 1 on 21s
        if (_currentTime == 21 && descriptionCount == 2) {
          typeIndex = 0;
          speed = description2Duration / description[1].length;
          handleShowDescription(1);
        }

        // -- detail by url
        if (!$(".js-gallery-detail-by-url").length) {
          if (_currentTime == 30) {
            VideoDetail.loopDetail();
            AudioDetail.off();
          }
        } else {
          if (_currentTime == 31) {
            $("body").addClass("a17--hide-detail");
          }

          if (_currentTime >= 34) {
            clearInterval(_videoDetail);
            $("body").removeClass("a17--restart a17--show-img a17--play a17--hide-detail a17--show-detail a17--show-name a17--zoom-in-img-detail a17--reverse-content");
            const endDetailTO = setTimeout(() => {
              // emit to web socket
              // WebSocketIO.emit("ACE17AIGallery", { uid: 0, playing: false, name: "" });

              $("body").addClass("a17--show");
              VideoDetail.stop();
              AudioDetail.stop();
              Gallery.audio.play();
              endDetail = true;
              clearTimeout(endDetailTO);
            }, 1500);
          }
        }
      }, 1000);
    },
  };

  // --- init
  const init = () => {
    if ($(".js-gallery-detail").length) {
      descriptionPosision = handleRandom() % 2;
    }
  };

  return {
    init,
    play: handlePlay,
    setHeightDescription: handleSetHeightDescription,
  };
})();

export default GalleryDetail;
