// --- utilities
import { Scrolllable } from "utilities";

// --- components
import {
  Gallery,
  GalleryDetail,
  GalleryRemote,
  NFCScanner,
  // WebSocket,
  Stage,
  StageRemote,
  Gate,
  WindowResize,
} from "components";

// --- App
const App = (() => {
  // --- run transition
  const runTransition = () => {
    $("body").removeClass("hold-transition");
  };

  // --- show content
  const showContent = () => {
    $(".js-a17").removeClass("a17--hide");
    setTimeout(() => {
      $("body").addClass("a17--show");
    }, 1600);
    // --- disable scroll
    Scrolllable.enable();
  };

  // --- ready
  const ready = () => {
    (($) => {
      // --- disable scroll
      Scrolllable.disable();

      // --- Global
      runTransition();
      showContent();
    })(jQuery);
  };

  // --- load
  const load = () => {
    (($) => {
      $(window).on("load", () => {
        // --- Project
        Gallery.init();
        GalleryDetail.init();
        GalleryRemote.init();
        NFCScanner.init();
        Stage.init();
        StageRemote.init();
        Gate.init();
        // WebSocket.init();
        WindowResize.init();
      });
    })(jQuery);
  };

  // --- init
  const init = () => {
    load();
    ready();
  };

  // --- return
  return {
    init,
  };
})();

// ---  run main js
App.init();
