/* ------------------------------------------------------------------------------
@name: Gate
@description: Gate
--------------------------------------------------------------------------------- */
// - utilities
import { Session } from "utilities";
// import { WebSocketIO } from "./WebSocket";

const Gate = (() => {
  const $elementVideo = $(".js-gate .a17-gate__video__item:nth-child(2) video");

  // - handlePlayVideoDecoration
  const handlePlayVideoDecoration = () => {
    const videoDecorationTO = setTimeout(() => {
      $elementVideo[0].play();
      clearTimeout(videoDecorationTO);
    }, 3000);
  };

  // - handleDefaultAttendance
  const handleDefaultAttendance = (isInit = true) => {
    let _datas = JSON.parse(Session.get("a17Gate"));
    _datas = _datas !== null ? _datas : [];
    if (_datas.length) {
      let _dataDisplay = _datas;
      if (_dataDisplay.length > 30) {
        _dataDisplay = _dataDisplay.slice(_dataDisplay.length - 30, _dataDisplay.length);
      }

      let _attendanceList = "";
      $.each(_dataDisplay, (idx, val) => {
        if ($(`#${val}`).length) {
          const _detail = JSON.parse($(`#${val}`).text());
          if (idx < _dataDisplay.length - 1) {
            _attendanceList += _detail.name + " &bull; ";
          } else {
            _attendanceList += _detail.name;
          }
        }
      });

      if (_datas.length > 30) {
        _attendanceList += " &bull; And " + (_datas.length - 30) + " more";
      }

      $(".js-gate .a17-gate__attendance__list").html(_attendanceList);
      if (isInit) {
        $("body").addClass("a17--show-attendance");
      }
    }
  };

  // - handleClickGallery
  const handleClickGallery = () => {
    // -- onclick id
    let _uidGate = [];
    $(".js-gate .a17-gate__invitation__item").each((idx, val) => {
      const _id = `#${$(val).attr("id")}`;
      _uidGate.push(_id);
    });
    const _uids = _uidGate.join(", ");

    // --- click
    $("body").on("click", _uids, (e) => {
      if (!$("body").hasClass("a17--show-greeting")) {
        const _id = $(e.currentTarget).attr("id");
        let _datas = JSON.parse(Session.get("a17Gate"));
        _datas = _datas !== null ? _datas : [];

        if (!_datas.includes(_id)) {
          _datas.unshift(_id);
          Session.set("a17Gate", JSON.stringify(_datas));
        }

        const _detail = JSON.parse($(e.currentTarget).text());
        let _name = _detail.name;
        if (_detail.title !== "") {
          _name = `${_detail.title} ${_detail.name}`;
        }

        $(".js-gate .a17-gate__greeting__name").text(_name);
        if (_detail.occupation !== "") {
          $(".js-gate .a17-gate__greeting__occupation").text(_detail.occupation);
        } else {
          $(".js-gate .a17-gate__greeting__occupation").text("");
        }

        const showGreeting = setTimeout(() => {
          // emit to web socket
          // WebSocketIO.emit("ACE17Gate", { detail: _detail });

          if ($("body").hasClass("a17--show-attendance")) {
            $("body").removeClass("a17--show-attendance");
          }
          $("body").addClass("a17--show-greeting");
          clearTimeout(showGreeting);
          // handleDefaultAttendance(false);
        }, 200);

        const hideGreeting = setTimeout(() => {
          $("body").removeClass("a17--show-greeting").addClass("a17--show-attendance");
          clearTimeout(hideGreeting);
        }, 3200);
      }
    });
  };

  // - init
  const init = () => {
    if ($(".js-gate").length) {
      handlePlayVideoDecoration();
      handleClickGallery();
      handleDefaultAttendance();
    }
  };
  return {
    init,
  };
})();

export default Gate;
