/* ------------------------------------------------------------------------------
@name: Components Index
@description: Indexing all components
--------------------------------------------------------------------------------- */

import Gallery from "./Gallery";
import GalleryDetail from "./GalleryDetail";
import GalleryRemote from "./GalleryRemote";
import NFCScanner from "./NFCScanner";
import WebSocket from "./WebSocket";
import Stage from "./Stage";
import StageRemote from "./StageRemote";
import Gate from "./Gate";
import WindowResize from "./WindowResize";

export { Gallery, GalleryDetail, GalleryRemote, NFCScanner, WebSocket, Stage, StageRemote, Gate, WindowResize };
