/* ------------------------------------------------------------------------------
@name: NFCScanner
@description: NFCScanner
--------------------------------------------------------------------------------- */

// - WebSocket
// import { WebSocketIO } from "./WebSocket";

// --- NFCScanner
const NFCScanner = (() => {
  // - handleActiveGallery
  const handleActiveGallery = () => {
    $(".js-gallery-scan .a17-scan__btn").on("click", async () => {
      try {
        const ndef = new NDEFReader();
        await ndef.scan();

        $("body").addClass("a17--nfc-on");

        ndef.addEventListener("readingerror", () => {
          console.log("Argh! Cannot read data from the NFC tag. Try another one?");
        });

        ndef.addEventListener("reading", ({ message, serialNumber }) => {
          const _uid = `#gallery${serialNumber.replaceAll(":", "").toUpperCase()}`;
          // WebSocketIO.emit("ACE17AIGallery", { uid: _uid });
        });
      } catch (error) {
        console.log("Argh! " + error);
      }
    });
  };

  // - handleActiveGate
  const handleActiveGate = () => {
    $(".js-gate-scan .a17-scan__btn").on("click", async () => {
      try {
        const ndef = new NDEFReader();
        await ndef.scan();

        $("body").addClass("a17--nfc-on");

        ndef.addEventListener("readingerror", () => {
          console.log("Argh! Cannot read data from the NFC tag. Try another one?");
        });

        ndef.addEventListener("reading", ({ message, serialNumber }) => {
          const _uid = `#gate${serialNumber.replaceAll(":", "").toUpperCase()}`;
          // WebSocketIO.emit("ACE17Gate", { uid: _uid });
        });
      } catch (error) {
        console.log("Argh! " + error);
      }
    });
  };

  // - init
  const init = () => {
    if ($(".js-gallery-scan").length) {
      handleActiveGallery();
    }

    if ($(".js-gate-scan").length) {
      handleActiveGate();
    }
  };

  return {
    init,
  };
})();

export default NFCScanner;
