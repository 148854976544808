/* ------------------------------------------------------------------------------
@name: Profile
@description: Profile
--------------------------------------------------------------------------------- */

import { Session } from "utilities";

const Stage = (() => {
  const _urlImage = $(".js-stage").attr("data-url-img") !== undefined ? $(".js-stage").attr("data-url-img") : "./assets/img/";

  const profile = [
    {
      // 0
      name: "Wendy Novianto",
      role: "Managing Director, ID",
      pic: _urlImage + "profiles/wendy.png",
    },
    {
      // 1
      name: "Pati Fertpolin",
      role: "Business & Programme Director, ID",
      pic: _urlImage + "profiles/polin.png",
    },
    {
      // 2
      name: "Gilroy T. Graham",
      role: "Design Director, ID",
      pic: _urlImage + "profiles/gilroy.png",
    },
    {
      // 3
      name: "Thomas Wijayanto",
      role: "Head of Tech at PT Astra Honda Motor",
      pic: _urlImage + "profiles/thomas.png",
    },
    {
      // 4
      name: "Riko Wardhana",
      role: "Head of Digital Banking Product<br/>at PT Bank Syariah Indonesia",
      pic: _urlImage + "profiles/riko.png",
    },
    {
      // 5
      name: "Danang Andrianto",
      role: "VP Customer Journey & Experience<br/>at Telkomsel",
      pic: _urlImage + "profiles/danang.png",
    },
    {
      // 6
      name: "Andri Hidayat",
      role: "Digital Service Transformation &<br/>IT Director at PT Prodia Widyahusada, Indonesia",
      pic: _urlImage + "profiles/andri.png",
    },
    {
      // 7
      name: "Arvind Singh",
      role: "CEO & Founder",
      pic: _urlImage + "profiles/arvind.png",
    },
  ];

  // - init
  const init = () => {
    if ($(".js-stage").length) {
      $("#stageTrigger").click(function () {
        changeScreen($("#screenid").val());
      });

      changeScreen("welcome");
      defaultAttendance();
    }
  };

  const changePerson = (id) => {
    $(".a17-brand").show();
    $(".a17-stage__greetings").removeClass("a17-stage--active-screen");
    $(".a17-stage").removeClass("a17-stage--fade-in");
    $(".a17-stage").addClass("a17-stage--fade-out");
    // $(".a17-stage__greetings").fadeOut(500);
    // $(".a17-stage__detect-card").fadeOut(500);
    // $(".a17-stage__digital-transformation").fadeOut(500);
    // $(".a17-stage__a17").fadeOut(1500);

    $(".a17-stage__screen, .a17-stage__digital-transformation__bg-effect video").fadeOut(500);

    const ss = setTimeout(() => {
      $(".a17-stage__profile").fadeIn();
    }, 1500);

    $(".a17-stage__avatar").removeClass("a17-stage__avatar--animate");
    $(".a17-stage__info").removeClass("a17-stage__info--animate");
    $(".a17-stage__bg-effect video").fadeOut(1500);

    const to1 = setTimeout(() => {
      $(".a17-stage__bg").removeClass("a17-stage__bg-loop-animation");
      $(".a17-stage").removeClass("a17-stage--fade-out");

      // Set data and reset animation
      $(".a17-stage__info-name").text(profile[id].name);
      $(".a17-stage__info-role").html(profile[id].role);
      $(".a17-stage__avatar").attr("src", profile[id].pic);

      $(".a17-stage__bg").addClass("a17-stage__bg-first-animation");
      $(".a17-stage__avatar").addClass("a17-stage__avatar--animate");
      $(".a17-stage").addClass("a17-stage--fade-in");
      $(".a17-stage__info").addClass("a17-stage__info--animate");
      $(".a17-stage__bg").addClass("a17-stage__bg-loop-animation");

      const to2 = setTimeout(() => {
        $(".a17-stage").removeClass("a17-stage--fade-in");
        clearTimeout(to2);
      }, 2500);

      const to3 = setTimeout(() => {
        $(".a17-stage__bg-effect video").fadeIn(1500);
        clearTimeout(to3);
      }, 3000);

      clearTimeout(to1);
    }, 2000);
  };

  const changeScreen = (code) => {
    switch (code) {
      case "welcome":
        $(".a17-stage__greetings__attendance").hide();
        $(".a17-brand").show();
        // -- active screen
        $(".a17-stage__greetings").addClass("a17-stage--active-screen");
        $(".a17-stage__screen:not(.a17-stage__greetings), .a17-stage__digital-transformation__bg-effect video").fadeOut(500);

        const sto = setTimeout(() => {
          $(".a17-stage__greetings").fadeIn(1000);
          clearTimeout(sto);
        }, 1000);
        break;

      case "welcome_with_attendance":
        $(".a17-brand").show();
        if ($(".a17-stage__greetings__attendance").hasClass("attendanced")) {
          $(".a17-stage__greetings__attendance").show();
        }
        // -- active screen
        $(".a17-stage__greetings").addClass("a17-stage--active-screen");
        $(".a17-stage__screen:not(.a17-stage__greetings), .a17-stage__digital-transformation__bg-effect video").fadeOut(500);

        const sto2 = setTimeout(() => {
          $(".a17-stage__greetings").fadeIn(1000);
          $("body").removeClass("a17--show-greeting-stage").addClass("a17--show-attendance-gate");
          clearTimeout(sto2);
        }, 501);
        break;

      case "digital_transformation":
        $(".a17-brand").show();
        $(".a17-stage__screen, .a17-stage__digital-transformation__bg-effect video").fadeOut(1500);
        // $(".a17-stage__profile").fadeOut(1500);
        // $(".a17-stage__detect-card").fadeOut(1500);
        // $(".a17-stage__greetings").fadeOut(1500);
        // $(".a17-stage__confetti1").fadeOut(1500);
        // $(".a17-stage__confetti2").fadeOut(1500);
        // $(".a17-stage__ace17").fadeOut(1500);
        // $(".a17-stage__digital-transformation__bg-effect video").fadeOut(1500);

        const sto4 = setTimeout(() => {
          $(".a17-stage__digital-transformation").fadeIn(2000);
          clearTimeout(sto4);
        }, 1000);

        const sto5 = setTimeout(() => {
          $(".a17-stage__digital-transformation__bg-effect video").fadeIn(1500);
          clearTimeout(sto5);
        }, 5000);

        break;

      case "detect_card":
        $(".a17-brand").show();
        $(".a17-stage__screen:not(.a17-stage__greetings)").fadeOut(0);

        const sto3 = setTimeout(() => {
          if ($("body").hasClass("a17--show-attendance-gate")) {
            $("body").removeClass("a17--show-attendance-gate");
          }
          $("body").addClass("a17--show-greeting-stage");
          clearTimeout(sto3);
          defaultAttendance();
        }, 5);
        break;
      case "ace17":
        $(".a17-brand").show();
        $(".a17-stage__screen, .a17-stage__digital-transformation__bg-effect video").fadeOut(1500);

        const sto6 = setTimeout(() => {
          $(".a17-stage__ace17").fadeIn(2000);
          clearTimeout(sto6);
        }, 1000);
        break;
      case "confetti1":
        $(".a17-brand").hide();
        $(".a17-stage__screen, .a17-stage__digital-transformation__bg-effect video").fadeOut(1500);

        const sto8 = setTimeout(() => {
          $(".a17-stage__confetti1").fadeIn(2000);
          clearTimeout(sto8);
        }, 1000);
        break;
      case "confetti2":
        $(".a17-brand").hide();
        $(".a17-stage__screen, .a17-stage__digital-transformation__bg-effect video").fadeOut(1500);

        const sto9 = setTimeout(() => {
          $(".a17-stage__confetti2").fadeIn(2000);
          clearTimeout(sto9);
        }, 1000);
        break;
      case "music1":
        $(".a17-brand").hide();
        $(".a17-stage__screen, .a17-stage__digital-transformation__bg-effect video").fadeOut(1500);

        const smusic1 = setTimeout(() => {
          $(".a17-stage__music1").fadeIn(2000);
          clearTimeout(smusic1);
        }, 1000);
        break;
      case "music2":
        $(".a17-brand").hide();
        $(".a17-stage__screen, .a17-stage__digital-transformation__bg-effect video").fadeOut(1500);

        const smusic2 = setTimeout(() => {
          $(".a17-stage__music2").fadeIn(2000);
          clearTimeout(smusic2);
        }, 1000);
        break;
      case "music3":
        $(".a17-brand").hide();
        $(".a17-stage__screen, .a17-stage__digital-transformation__bg-effect video").fadeOut(1500);

        const smusic3 = setTimeout(() => {
          $(".a17-stage__music3").fadeIn(2000);
          clearTimeout(smusic3);
        }, 1000);
        break;
      case "music4":
        $(".a17-brand").hide();
        $(".a17-stage__screen, .a17-stage__digital-transformation__bg-effect video").fadeOut(1500);

        const smusic4 = setTimeout(() => {
          $(".a17-stage__music4").fadeIn(2000);
          clearTimeout(smusic4);
        }, 1000);
        break;
      case "music5":
        $(".a17-brand").hide();
        $(".a17-stage__screen, .a17-stage__digital-transformation__bg-effect video").fadeOut(1500);

        const smusic5 = setTimeout(() => {
          $(".a17-stage__music5").fadeIn(2000);
          clearTimeout(smusic5);
        }, 1000);
        break;
      case "party":
        $(".a17-brand").hide();
        $(".a17-stage__screen, .a17-stage__digital-transformation__bg-effect video").fadeOut(1500);

        const party = setTimeout(() => {
          $(".a17-stage__party").fadeIn(2000);
          clearTimeout(party);
        }, 1000);
        break;
      case "wendy":
        changePerson(0);
        break;
      case "polin":
        changePerson(1);
        break;
      case "gilroy":
        changePerson(2);
        break;
      case "thomas":
        changePerson(3);
        break;
      case "riko":
        changePerson(4);
        break;
      case "danang":
        changePerson(5);
        break;
      case "andri":
        changePerson(6);
        break;
      case "arvind":
        changePerson(7);
        break;
      default:
        changeScreen("welcome");
        break;
    }
  };

  // - defaultAttendance
  const defaultAttendance = () => {
    let _datas = JSON.parse(Session.get("a17Gate"));
    _datas = _datas !== null ? _datas : [];
    if (_datas.length) {
      let _dataDisplay = _datas;
      if (_dataDisplay.length > 30) {
        _dataDisplay = _dataDisplay.slice(_dataDisplay.length - 30, _dataDisplay.length);
      }

      let _attendanceList = "";
      $.each(_dataDisplay, (idx, val) => {
        if ($(`#${val}`).length) {
          const _detail = JSON.parse($(`#${val}`).text());
          if (idx < _dataDisplay.length - 1) {
            _attendanceList += _detail.name + " &bull; ";
          } else {
            _attendanceList += _detail.name;
          }
        }
      });

      if (_datas.length > 30) {
        _attendanceList += " &bull; And " + (_datas.length - 30) + " more";
      }

      const stoATO = setTimeout(() => {
        $(".js-stage .a17-stage__greetings__attendance-list").html(_attendanceList);
        clearTimeout(stoATO);
      }, 500);

      $(".a17-stage__greetings__attendance").addClass("attendanced");

      const stoDTO = setTimeout(() => {
        changeScreen("welcome_with_attendance");
        clearTimeout(stoDTO);
      }, 3000);
    }
  };

  return {
    init,
    changePerson,
    changeScreen,
  };
})();

export default Stage;
