/* ------------------------------------------------------------------------------
@name: Api
@description: Api
--------------------------------------------------------------------------------- */

import { Session } from "utilities";

// - stage
import Stage from "./Stage";

// export const WebSocketIO = io.connect("ws://172.20.10.120:3000/");

// --- WebSocket
const WebSocket = (() => {
  // - handleWebSocket
  const handleWebSocket = () => {
    // - connect
    WebSocketIO.on("connect", (e) => {
      console.log("WebSocket Connected");
    });

    // ACE17AIGallery
    WebSocketIO.on("ACE17AIGallery", (data) => {
      // screen gallery
      if (data.uid !== 0 && data.uid !== undefined && $(".js-gallery").length) {
        $(data.uid).trigger("click");
      }

      if (data.playing && data.name !== undefined) {
        handleSetNameOnRemote(data.name);
        $("body").addClass("show-toast");
      } else {
        $("body").removeClass("show-toast");
      }
    });

    // ACE17Gate
    WebSocketIO.on("ACE17Gate", (data) => {
      // gate screen
      if ($(".js-gate").length) {
        if (data.uid !== 0 && data.uid !== undefined) {
          $(data.uid).trigger("click");
        }
      }

      // stage screen
      if ($(".js-stage").length) {
        if (data.detail !== undefined && $(".a17-stage__greetings").hasClass("a17-stage--active-screen")) {
          let _datas = JSON.parse(Session.get("a17Gate"));
          _datas = _datas !== null ? _datas : [];
          const _id = `gate${data.detail.uid.replaceAll(":", "").toUpperCase()}`;

          if (!_datas.includes(_id)) {
            _datas.unshift(_id);
            Session.set("a17Gate", JSON.stringify(_datas));
          }

          let _name = data.detail.name;

          if (data.detail.title !== "") {
            _name = `${data.detail.title} ${data.detail.name}`;
          }

          $(".js-stage .a17-stage__greetings__name").text(_name);
          if (data.detail.occupation !== "") {
            $(".js-stage .a17-stage__greetings__role").text(data.detail.occupation);
          } else {
            $(".js-stage .a17-stage__greetings__role").text("");
          }

          Stage.changeScreen("detect_card");
        }
      }
    });

    // ACE17MainStage
    WebSocketIO.on("ACE17MainStage", (data) => {
      $("#screenid").val(data.screen);
      const sto = setTimeout(() => {
        $("#stageTrigger").trigger("click");
        clearTimeout(sto);
      }, 500);

      // Kalau mau nampilin nama orang yang datang tinggal begini ya..
      // $('#screenid').val('detect_card');
      // const sto = setTimeout(() => {
      //   $('#stageTrigger').trigger("click");
      // clearTimeout(sto);
      // }, 500);
    });
  };

  // - handleSetNameOnRemote
  const handleSetNameOnRemote = (name) => {
    if ($(".js-gallery-remote").length) {
      $(".js-gallery-remote .a17-gallery-remote__toast").text(`AI Gallery ${name} is Playing`);
    }
  };

  // - init
  const init = () => {
    // handleWebSocket();
  };

  return {
    init,
    setNameOnRemote: handleSetNameOnRemote,
  };
})();

export default WebSocket;
