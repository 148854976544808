/* ------------------------------------------------------------------------------
@name: GalleryRemote
@description: GalleryRemote
--------------------------------------------------------------------------------- */

// - WebSocket
// import WebSocket, { WebSocketIO } from "./WebSocket";

// --- GalleryRemote
const GalleryRemote = (() => {
  // - handleSearchName
  const handleSearchName = () => {
    $(".js-gallery-remote .a17-gallery-remote__form__input").on("input", (e) => {
      const _keyword = $(e.currentTarget).val().toLowerCase();
      const _item = $(".js-gallery-remote .a17-gallery-remote__item");
      for (let i = 0; i < _item.length; i++) {
        const _text = $(_item[i]).find(".a17-gallery-remote__name").text();
        if (_text.toLowerCase().indexOf(_keyword) > -1) {
          $(_item[i]).removeClass("a17-gallery-remote__item--hide");
        } else {
          $(_item[i]).addClass("a17-gallery-remote__item--hide");
        }
      }
    });
  };

  // - handleTriggerClick
  const handleTriggerClick = () => {
    $(".js-gallery-remote .a17-gallery-remote__btn").on("click", (e) => {
      const _uid = $(e.currentTarget).attr("data-uid");
      const _name = $(e.currentTarget).parents(".a17-gallery-remote__item").find(".a17-gallery-remote__name").text();

      if (!$("body").hasClass("running")) {
        // WebSocketIO.emit("ACE17AIGallery", { uid: _uid, playing: true, name: _name });
        // WebSocket.setNameOnRemote(_name);
        $("body").addClass("show-toast");
      }
    });
  };

  // - init
  const init = () => {
    if ($(".js-gallery-remote").length) {
      handleSearchName();
      handleTriggerClick();
    }
  };

  return {
    init,
  };
})();

export default GalleryRemote;
