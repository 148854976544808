/* ------------------------------------------------------------------------------
@name: StageRemote
@description: StageRemote
--------------------------------------------------------------------------------- */

// - WebSocket
// import WebSocket, { WebSocketIO } from "./WebSocket";

// --- StageRemote
const StageRemote = (() => {
  // - handleTriggerClick
  const handleTriggerClick = () => {
    $(".js-stage-remote .a17-stage-remote__btn").on("click", (e) => {
      const _screen = $(e.currentTarget).attr("data-screen");
      const _name = $(e.currentTarget).parents(".a17-stage-remote__item").find(".a17-stage-remote__name").text();

      if (!$("body").hasClass("running")) {
        // WebSocketIO.emit("ACE17MainStage", { screen: _screen });
        // WebSocket.setNameOnRemote(_name);
      }
    });
  };

  // - init
  const init = () => {
    if ($(".js-stage-remote").length) {
      handleTriggerClick();
    }
  };

  return {
    init,
  };
})();

export default StageRemote;
