/* ------------------------------------------------------------------------------
@name: Gallery
@description: Gallery
--------------------------------------------------------------------------------- */

// - utilities
import { Session } from "utilities";
// import { WebSocketIO } from "./WebSocket";

// - GalleryDetail
import GalleryDetail from "./GalleryDetail";

// --- Gallery
const Gallery = (() => {
  // --- selector
  const $elementAudio = $(".js-gallery-audios .a17-gallery__audio__main audio");
  const $elementVideo = $(".js-gallery-video-pattern .a17-gallery__decoration__item:nth-child(2) video");

  // presentationMode
  let presentationMode = false;
  let presentationOngoing = 0;
  let presentationPerson = {
    87: "wendy",
    67: "cake",
    65: "arvind",
  };

  // delay
  const delayStartDetail = 1300;
  const delayAppendItem = 5000;

  // - handlePlayVideoDecoration
  const handlePlayVideoDecoration = () => {
    const videoDecorationTO = setTimeout(() => {
      $elementVideo[0].play();
      clearTimeout(videoDecorationTO);
    }, 2500);
  };

  // - handleDefaultGallery
  const handleDefaultGallery = () => {
    let _datas = JSON.parse(Session.get("a17Gallery"));
    _datas = _datas !== null ? _datas : [];
    if (_datas.length) {
      if (_datas.length > 30) {
        _datas = _datas.slice(_datas.length - 30, _datas.length);
      }
      $.each(_datas, (idx, val) => {
        const _item = $(`#${val}`).parents(".a17-gallery__item");
        const _htmlItem = _item.wrap("<div/>").parent().html();
        _item.unwrap().remove();
        $(".js-gallery .a17-gallery__list").prepend(_htmlItem);
      });
    }
  };

  // - handleClickGallery
  const handleClickGallery = () => {
    // -- onclick id
    let _uidGallery = [];
    $(".js-gallery .a17-gallery__item").each((idx, val) => {
      const _id = `#${$(val).find("button").attr("id")}`;
      _uidGallery.push(_id);
    });
    const _uids = _uidGallery.join(", ");

    // --- click
    $("body").on("click", _uids, (e) => {
      if (!$("body").hasClass("a17--play") && !presentationMode) {
        const _id = $(e.currentTarget).attr("id");
        let _datas = JSON.parse(Session.get("a17Gallery"));
        _datas = _datas !== null ? _datas : [];

        if (!_datas.includes(_id)) {
          _datas.push(_id);
          Session.set("a17Gallery", JSON.stringify(_datas));
        }

        // get element
        const _htmlItem = $(e.currentTarget).parents(".a17-gallery__item").wrap("<div/>").parent().html();
        $(e.currentTarget).parents(".a17-gallery__item").unwrap();

        const _detail = JSON.parse($(e.currentTarget).text());
        Audio.stop();
        $("body").addClass("a17--play");

        const playDetailTO = setTimeout(() => {
          GalleryDetail.play(_detail);
          // WebSocketIO.emit("ACE17AIGallery", { uid: _detail.uid, playing: true, name: _detail.name });
          clearTimeout(playDetailTO);
        }, delayStartDetail);

        const reorderTO = setTimeout(() => {
          // reorder
          $(e.currentTarget).parents(".a17-gallery__item").remove();
          $(".js-gallery .a17-gallery__list").prepend(_htmlItem);
          clearTimeout(reorderTO);
        }, delayAppendItem);
      }
    });
  };

  // - handlePauseOnPresent
  const handlePauseOnPresent = () => {
    $(document).on("keydown", (e) => {
      if (e.which === 87 || e.which === 67 || e.which === 65) {
        if (presentationMode && presentationOngoing === e.which) {
          presentationOngoing = 0;
          presentationMode = false;
          $("body").removeClass("a17--pause");
          if (!Audio.mute) {
            $($elementAudio[0]).animate({ volume: 1 }, 2000);
          }
        } else {
          if (!Audio.mute) {
            $($elementAudio[0]).animate({ volume: 0 }, 2000);
          }
          presentationMode = true;
          presentationOngoing = e.which;
          if ($(".js-gallery-pause .a17-pause__desc").hasClass("a17-pause__desc--active")) {
            $(".js-gallery-pause .a17-pause__desc").removeClass("a17-pause__desc--active");
          }
          $(`.js-gallery-pause .a17-pause__desc[data-content="${presentationPerson[e.which]}"]`).addClass("a17-pause__desc--active");
          $("body").addClass("a17--pause");
        }
      }
    });
  };

  // --- Audio
  const Audio = {
    mute: false,
    play: () => {
      $elementAudio[0].play();
      $elementAudio[0].loop = true;
      $elementAudio[0].currentTime = 0;
      $($elementAudio[0]).animate({ volume: 1 }, 1000);
      Audio.mute = false;
      Audio.muted();
    },
    pause: () => {
      $elementAudio[0].pause();
    },
    stop: () => {
      $($elementAudio[0]).animate({ volume: 0 }, 2200);
      const audioStopTO = setTimeout(() => {
        Audio.mute = true;
        Audio.muted();
        Audio.pause();
        $elementAudio[0].currentTime = 0;
        clearTimeout(audioStopTO);
      }, 2000);
    },
    muted: () => {
      $elementAudio[0].muted = Audio.mute;
    },
    keyPlay: () => {
      let _playAudio = false;

      $(document).on("focus", (e) => {
        if (!_playAudio) {
          Audio.play();
          _playAudio = true;
        }
      });

      $(document).on("keydown", (e) => {
        if (e.which === 80 && !_playAudio) {
          Audio.play();
          _playAudio = true;
        }
      });
    },
    keyMute: () => {
      $(document).on("keydown", (e) => {
        if (e.which === 77) {
          Audio.mute = !Audio.mute;
          Audio.muted();
        }
      });
    },
  };

  // - handleAutoPlayByURL
  const handleAutoPlayByURL = () => {
    if ($(".js-gallery-detail-by-url").length) {
      setTimeout(() => {
        const _uid = $(".js-gallery-detail-by-url").val();
        $(`#${_uid}`).trigger("click");
      }, 1000);
    }
  };

  // - init
  const init = () => {
    if ($(".js-gallery").length) {
      handleDefaultGallery();
      handlePlayVideoDecoration();
      handleClickGallery();
      handlePauseOnPresent();
      Audio.keyPlay();
      Audio.keyMute();
      handleAutoPlayByURL();
    }
  };

  return {
    init,
    audio: {
      play: Audio.play,
      stop: Audio.stop,
    },
  };
})();

export default Gallery;
